import React, { useState } from "react";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  // NavbarText,
} from "reactstrap";

import langId from "translation/id/home.json";
import langJpn from "translation/jpn/home.json";

import "./index.scss";
import Logo from "assets/logo-default.png";
// import FlagId from "assets/id.svg";
// import FlagJp from "assets/jp.svg";

const webText = {
  id: langId,
  jpn: langJpn,
};

const NavigationBar = (props) => {
  // const { scrollPosition, setLang, lang } = props;
  const { scrollPosition, lang } = props;

  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  const url = window.location.pathname;

  // const toggleLang = (val) => {
  //   setLang(val);
  //   localStorage.setItem("lang", val);
  //   toggle();
  // };

  const links = [
    {
      name: "home",
      url: "/",
    },
    {
      name: "ini_kami",
      url: "/ini-kami",
    },
    {
      name: "program",
      url: "/program",
    },
    {
      name: "pelatihan",
      url: "/pelatihan",
    },
    {
      name: "media",
      url: "/media",
    },
    // {
    //   name: "kontak-kami",
    //   url: "/kontak-kami",
    // },
  ];

  return (
    <Navbar
      expand="xl"
      container="xl"
      className={`custom-navigation-bar bg-white${scrollPosition > 0 ? " shadow" : ""}`}
      fixed={`${scrollPosition > 0 ? "top" : ""}`}
    >
      <NavbarBrand href="/">
        <img src={Logo} alt="navbar-logo" />
      </NavbarBrand>
      <NavbarToggler onClick={toggle} className="border-0" />
      <Collapse isOpen={isOpen} navbar>
        <Nav className="me-auto" navbar>
          {links.map((link, index) => (
            <NavItem key={index}>
              <NavLink href={link.url} active={url === link.url}>
                {webText[lang].navbar[link.name]}
              </NavLink>
            </NavItem>
          ))}
        </Nav>
        {/* <NavbarText
          tag="div"
          className="d-flex align-items-center justify-content-center bg-secondary bg-opacity-25 flag-toggler-container"
        >
          <img
            onClick={() => toggleLang("id")}
            className="cursor-pointer flag-toggler"
            src={FlagId}
            alt="flag-id"
          />
          /
          <img
            onClick={() => toggleLang("jpn")}
            className="cursor-pointer flag-toggler"
            src={FlagJp}
            alt="flag-jpn"
          />
        </NavbarText> */}
      </Collapse>
    </Navbar>
  );
};

export default NavigationBar;
