import React from "react";
import {
  // Card,
  // CardImg,
  // CardImgOverlay,
  // CardText,
  // CardTitle,
  Col,
  Container,
  Row,
} from "reactstrap";

import langId from "translation/id/home.json";
import langJpn from "translation/jpn/home.json";

import "./index.scss";
import LogoCircle from "assets/logo-circle.png";
import ProgramImg from "assets/home/img-home-1-01.jpg";
import TrainingCenterImg from "assets/home/img-home-2-01.jpg";

// const mock = [
//   {
//     title: "2019 November",
//     text: "JSBM 2019 (Joint Seminar Business Matching) yang diadakan di Tokyo untuk mempertemukan SO Indonesia dengan AO Jepang.",
//   },
//   {
//     title: "2023 Agustus",
//     text: "Kerjasama dengan Universitas Keperawatan",
//   },
//   {
//     title: "2023 Agustus",
//     text: "Kerjasama dengan SMK Tasik",
//   },
// ];

const webText = {
  id: langId,
  jpn: langJpn,
};

const Home = (props) => {
  const { isMediumDevice, lang, isLargeDevice } = props;

  return (
    <>
      <Container>
        {/* {isMediumDevice ? (
          <Row className="mb-5 head-article mobile">
            {mock.map((item, index) => (
              <Col key={index} sm="12" className="mb-3">
                <Card inverse className="h-100 mb-3">
                  <CardImg
                    alt="Card image cap"
                    src="https://picsum.photos/900/270?grayscale"
                    style={{
                      height: "100%",
                      objectFit: "cover",
                    }}
                    width="100%"
                  />
                  <CardImgOverlay>
                    <CardTitle tag="h5" className="mb-3">
                      {item.title}
                    </CardTitle>
                    <CardText>{item.text}</CardText>
                  </CardImgOverlay>
                </Card>
              </Col>
            ))}
          </Row>
        ) : (
          <Row className="mb-5 head-article">
            <Col xl="6">
              {mock[0] && (
                <Card inverse className="h-100">
                  <CardImg
                    alt="Card image cap"
                    src="https://picsum.photos/900/270?grayscale"
                    style={{
                      height: "100%",
                      objectFit: "cover",
                    }}
                    width="100%"
                  />
                  <CardImgOverlay>
                    <CardTitle tag="h5" className="mb-3">
                      {mock[0].title}
                    </CardTitle>
                    <CardText>{mock[0].text}</CardText>
                  </CardImgOverlay>
                </Card>
              )}
            </Col>
            <Col xl="6">
              {mock.slice(1, 3).map((item, index) => (
                <Card
                  inverse
                  className={
                    index === 0 ? "mb-3 card-secondary" : "card-secondary"
                  }
                  key={index}
                >
                  <CardImg
                    alt="Card image cap"
                    src="https://picsum.photos/900/270?grayscale"
                    style={{
                      height: "100%",
                      objectFit: "cover",
                    }}
                    width="100%"
                  />
                  <CardImgOverlay>
                    <CardText>
                      {item.title} - {item.text}
                    </CardText>
                  </CardImgOverlay>
                </Card>
              ))}
            </Col>
          </Row>
        )} */}
        <Row className="my-5">
          {isMediumDevice ? (
            <Col xs="12" className="d-flex justify-content-center mb-5">
              <img
                src={LogoCircle}
                alt="logo-circle"
                style={{
                  height: "20vh",
                }}
              />
            </Col>
          ) : (
            <Col
              xl="6"
              className="d-flex align-items-center justify-content-center"
            >
              <img
                src={LogoCircle}
                alt="logo-circle"
                width={"45%"}
                height="auto"
              />
            </Col>
          )}
          <Col xl="6" className={isLargeDevice ? "mt-3 text-center" : ""}>
            <h3>{webText[lang].sending_organization.title}</h3>
            <hr
              className={`custom-horizontal-line ${isLargeDevice ? "mx-auto" : ""}`}
            />
            <p>{webText[lang].sending_organization.text}</p>
            {/* <p>
              <strong>Selengkapnya</strong>
            </p> */}
          </Col>
        </Row>
      </Container>
      <div className="mb-5 text-center student-counter">
        <Container className="py-3">
          <Row className="mb-3">
            <Col xs="12">
              <h3 className="my-3">Total Siswa : 378 Siswa</h3>
            </Col>
          </Row>
          {isMediumDevice ? (
            <>
              <Row className="mb-3">
                <Col xs="9">
                  <p className="text-start">
                    Siswa dalam masa pelatihan untuk berangkat ke Jepang
                  </p>
                </Col>
                <Col
                  xs="3"
                  className="d-flex align-items-center justify-content-center"
                >
                  <h3>
                    <strong>76</strong>
                  </h3>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col xs="9">
                  <p className="text-start">
                    Siswa dalam masa magang di Jepang saat ini
                  </p>
                </Col>
                <Col
                  xs="3"
                  className="d-flex align-items-center justify-content-center"
                >
                  <h3>
                    <strong>230</strong>
                  </h3>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col xs="9">
                  <p className="text-start">
                    Siswa yang telah menyelesaikan masa pemagangan di Jepang
                  </p>
                </Col>
                <Col
                  xs="3"
                  className="d-flex align-items-center justify-content-center"
                >
                  <h3>
                    <strong>72</strong>
                  </h3>
                </Col>
              </Row>
            </>
          ) : (
            <>
              <Row className="mb-3">
                <Col lg="4">
                  <p>Siswa dalam masa pelatihan untuk berangkat ke Jepang</p>
                </Col>
                <Col lg="4">
                  <p>Siswa dalam masa magang di Jepang saat ini</p>
                </Col>
                <Col lg="4">
                  <p>
                    Siswa yang telah menyelesaikan masa pemagangan di Jepang
                  </p>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col lg="4">
                  <h3>
                    <strong>76</strong>
                  </h3>
                </Col>
                <Col lg="4">
                  <h3>
                    <strong>230</strong>
                  </h3>
                </Col>
                <Col lg="4">
                  <h3>
                    <strong>72</strong>
                  </h3>
                </Col>
              </Row>
            </>
          )}
        </Container>
      </div>
      <Container>
        <Row className="my-5">
          <Col xs="12" xl="7">
            <img
              src={ProgramImg}
              alt="banner"
              style={{
                height: isMediumDevice ? "10rem" : "20rem",
                width: "100%",
                objectFit: "cover",
              }}
            />
          </Col>
          <Col
            xs="12"
            xl="5"
            className={isMediumDevice || isLargeDevice ? "mt-3" : ""}
          >
            <h3>Program</h3>
            <hr className="custom-horizontal-line" />
            <p>
              Jenis kerja yang akan diambil siswa cukup beragam dan durasi
              kontrak selama menjadi pemagangan pun bervariasi. Ada banyak hal
              yang siswa harus pahami mengenai program magang ini agar tidak
              salah langkah dalam mengambil keputusan baik dalam jenis kerja
              ataupun dalam jenis visa.
            </p>
            {/* <p>
              <strong>Selengkapnya</strong>
            </p> */}
          </Col>
        </Row>
        <Row className="my-5">
          <Col xs="12" xl="7">
            <img
              src={TrainingCenterImg}
              alt="banner"
              style={{
                height: isMediumDevice ? "10rem" : "20rem",
                width: "100%",
                objectFit: "cover",
              }}
            />
          </Col>
          <Col
            xs="12"
            xl="5"
            className={isMediumDevice || isLargeDevice ? "mt-3" : ""}
          >
            <h3>Training Center</h3>
            <hr className="custom-horizontal-line" />
            <p>
              Magang akan membawa Peserta keluar dari zona nyaman mereka,
              menempuh perjalanan dan pengalaman hidup di Jepang akan mengubah
              perspektif hidup mereka, membawa pengalaman baru diluar CV mereka.
              Maka dari itu kami bekali peserta dengan dasar-dasar mental yang
              kuat!
            </p>
            {/* <p>
              <strong>Selengkapnya</strong>
            </p> */}
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Home;
