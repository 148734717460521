import React from "react";
import { Col, Container, Row } from "reactstrap";

const Media = (props) => {
  return (
    <Container>
      <Row className="my-5">
        <Col xs="12">
          <iframe
            width="100%"
            height="540"
            src="https://www.youtube.com/embed/kLb9eDPrtDI?si=Aedv2J3pH015a_AK"
            title="Asta Karya Video Profile"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
          ></iframe>
        </Col>
      </Row>
    </Container>
  );
};

export default Media;
