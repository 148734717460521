import React from "react";
import { Col, Container, Row } from "reactstrap";

import LogoCircle from "assets/logo-circle.png";
import StrukturOrganisasi from "assets/struktur-organisasi.svg";
import IniKamiHeadImg from "assets/ini-kami/img-inikami-1-01.jpg";
import IniKamiSideImg from "assets/ini-kami/img-inikami-2-01.jpg";

const IniKami = (props) => {
  const { isMediumDevice, isLargeDevice } = props;

  return (
    <>
      <Container>
        <Row>
          <Col
            xs="12"
            className="d-flex align-items-center justify-content-center my-5"
          >
            <img
              src={LogoCircle}
              alt="logo-circle"
              style={{
                height: "20vh",
              }}
            />
          </Col>
          <Col xs="12">
            <h3 className="text-center">SO (Sending Organization)</h3>
            <hr className="custom-horizontal-line mx-auto" />
            <p>
              Pemagangan WNI ke Jepang disepakati kedua negara tahun 1993 dengan
              tujuan untuk melatih pemuda Indonesia di Jepang dan setelah
              selesai pemagangan kembali ke Indonesia untuk membangun Indonesia.
              Pola perekrutan skema pemagangan dilakukan melalui kerja sama
              antara Lembaga Pelatihan Kerja (LPK) yang memiliki izin pengiriman
              (Sending Organization/SO) di Indonesia dengan organisasi penerima
              (AO) di Jepang. AO selanjutnya menempatkan pemagang di
              perusahaan-perusahaan pengguna (implementing) di seluruh Jepang.
            </p>
            <p>
              Skema magang ini mengharuskan calon pemagang untuk mendapatkan
              pelatihan terlebih dahulu di Indonesia selama 3 bulan serta 1
              bulan di Jepang khususnya untuk penguasaan Bahasa Jepang. Sesuai
              ketentuan ketenagakerjaan di Jepang, pemagang di Jepang maksimal 5
              tahun, tidak boleh berpindah sektor pekerjaan dan perusahaan dan
              tidak boleh membawa keluarga. Bagi yang telah menyelesaikan
              kontrak pemagangan dan kembali ke Indonesia, tidak boleh kembali
              ke Jepang sebagai trainee.
            </p>
            <p>
              Instansi berwenang di Jepang dalam Pemagangan adalah Japan
              International Training Cooperation Organization (JITCO) sebagai
              instansi mempromosikan dan Organization for Technical Intern
              Training (OTIT) sebagai Pengawas.
            </p>
            <p>
              Source :{" "}
              <a
                href="https://kemlu.go.id/tokyo/id"
                target="_blank"
                rel="noreferrer"
              >
                https://kemlu.go.id/tokyo/id
              </a>
            </p>
          </Col>
        </Row>
      </Container>
      <img
        src={IniKamiHeadImg}
        alt="banner"
        className="my-5"
        style={{
          height: isMediumDevice ? "10rem" : "20rem",
          width: "100%",
          objectPosition: "top",
          objectFit: "cover",
        }}
      />
      <Container>
        <Row className="mb-5">
          <Col xl="5" xs="12" className={isMediumDevice ? "mb-3" : ""}>
            {isMediumDevice ? (
              <img
                src={IniKamiSideImg}
                alt="banner"
                style={{
                  height: "40vh",
                  width: "100%",
                  objectFit: "cover",
                }}
              />
            ) : isLargeDevice ? (
              <img
                src={IniKamiSideImg}
                alt="banner"
                style={{
                  height: "30vh",
                  width: "100%",
                  objectFit: "cover",
                }}
              />
            ) : (
              <img
                src={IniKamiSideImg}
                alt="banner"
                style={{
                  height: "100%",
                  width: "100%",
                  objectFit: "cover",
                }}
              />
            )}
          </Col>
          <Col xl="7" xs="12">
            <h3
              className={`${isLargeDevice ? "mt-3" : ""} ${isMediumDevice ? "text-center" : ""}`}
            >
              Sejarah Kami
            </h3>
            <hr
              className={`custom-horizontal-line ${isMediumDevice ? "mx-auto" : ""}`}
            />
            <p>
              Lembaga Pelatihan Kerja (LPK). Asta Karya didirikan pada Desember
              2016 di Tebet, Jakarta Selatan oleh seorang Alumni Sekolah Bahasa
              Jepang (Nihon-go Gakkou), dengan tujuan untuk memberikan
              kesempatan kepada para pemuda pemudi Indonesia untuk merasakan
              atmosfer belajar dan bekerja (magang) di Jepang. Selain itu, juga
              untuk memenuhi passion dari pendirinya yaitu mengajar dan
              mengamalkan ilmu yang sudah didapat selama ini, demi sebuah tujuan
              akhir untuk ikut mencerdaskan dan mensejahterakan kehidupan bangsa
              dan negara.
            </p>
            <p>
              Pendiri sendiri pun selama menempuh pendidikan di Nihongo Gakkou
              juga melakukan pekerjaan paruh waktu (arubaito) di beberapa tempat
              (convenience store, restoran, pabrik, dll), sehingga paham akan
              atmosfer, tata karma dan etos kerja masyarakat Jepang yang
              terkenal akan kedisiplinan, ketepatan waktu dan Totalitasnya.
            </p>
            <p>
              Sifat-sifat tersebutlah yang ingin pendiri tularkan dan sebarkan
              kepada para pemuda pemudi Indonesia, dengan salah satu cara yaitu
              mengirimkan mereka untuk magang di perusahaan-perusahaan yang ada
              di Jepang, dengan harapan bahwa para pemuda pemudi Indonesia dapat
              belajar dan menyerap segala hal-hal bagus (baik itu ilmu,
              profesionalisme, pola pikir, dll) yang didapat selama menjalani
              proses magang, dan menerapkan dan menyebarkannya di Indonesia
              dalam kehidupan profesional mereka setelah selesai menjalani
              proses magang.
            </p>
            <h3 className={isMediumDevice ? "text-center" : ""}>
              Visi dan Misi
            </h3>
            <hr
              className={`custom-horizontal-line ${isMediumDevice ? "mx-auto" : ""}`}
            />
            <p>
              Meciptakan pelatihan kerja yang profesional dan berkualitas untuk
              dapat mewujudkan Sumber Daya Manusia yang terampil, ahli,
              produktif, kompeten dan dapat bersaing di era globalisasi.
            </p>
            <ul>
              <li>
                Memberdayakan sumber daya manusia Indonesia melalui perjalanan
                dan pengalaman hidup di Jepang yang dapat mengubah perspektif
                kehidupan menjadi lebih baik.
              </li>
              <li>
                Meningkatkan kualitas pendidikan dan kompetensi tenaga kerja
                melalui berbagai jenis pelatihan ketrampilan dan Bahasa Jepang
              </li>
              <li>
                Meningkatkan kualitas dan kwantitas tenaga kerja dalam rangka
                mengurangi pengangguran dan mendorong berkembangnya
                produktifitas tenaga kerja.
              </li>
              <li>
                Memperkuat hubungan internasional, globalisasi, kontribusi ke
                masyarakat internasional.
              </li>
            </ul>
          </Col>
        </Row>
        <Row className="mb-5">
          {/* <Col xl="5" xs="12" className={isMediumDevice ? "mb-3" : ""}>
            <img
              src="https://picsum.photos/900/270?grayscale"
              alt="banner"
              style={{
                height: "100%",
                width: "100%",
                objectFit: "cover",
              }}
            />
          </Col> */}
          <Col xl="12" xs="12">
            {/* <h3 className={isMediumDevice ? "text-center" : ""}>Pesan dari CEO</h3> */}
            <h3 className="text-center">Pesan dari CEO</h3>
            {/* <hr
              className={`custom-horizontal-line ${isMediumDevice ? "mx-auto" : ""}`}
            /> */}
            <hr className={`custom-horizontal-line mx-auto`} />
            <p>
              LPK Asta Karya adalah lembaga pelatihan kerja untuk mempersiapkan
              peserta magang agar mampu bertahan hidup di negara Jepang. Tidak
              mudah hidup di negara canggih ini, perlu keahlian dan kemampuan
              khusus sesuai dengan bidang pekerjaan yang akan menjadi tempat
              bekerja. Adapun materi pelatihan kerja yang kami berikan tidak
              hanya teori melainkan praktik sehingga peserta yang akan magang di
              jepang memiliki kesiapan yang matang.
            </p>
            <p>
              Kurikulum yang kami suguhkan terbagi menjadi tiga bagian yakni
              materi dasar, materi inti, dan materi khusus. Materi dasar terdiri
              dari keterampilan bahasa, pemahaman pola hidup di jepang, latihan
              fisik, serta materi survival life in Japan. Materi inti terdiri
              dari pengetahuan dalam bagaimana bekerja dan me- mahami setiap
              bidang yang akan digeluti di tempat peserta magang. Baik dalam
              sistem kerja dan pemahaman etos kerja masyarakat jepang. Materi
              khusus terdiri materi keterampilan di perusahaan peserta magang.
            </p>
            <p>Kelebihan-kelebihan dari program ini :</p>
            <ul>
              <li>
                Peningkatan dan perbaikan taraf hidup pemagang, melalui
                penerapan kemampuan keterampilan yang diperolehnya setelah
                kembali ke negaranya.
              </li>
              <li>
                Peningkatan kontrol kualitas, peningkatan disiplin, kesadaran
                akan biaya di perusahaan tempat pemagang nantinya bekerja.
              </li>
              <li>
                Memperkuat hubungan internasional, globalisasi, kontribusi ke
                masyarakat internasional.
              </li>
            </ul>
          </Col>
        </Row>
        <Row className="mb-5">
          <Col xs="12">
            <h3 className="text-center">Struktur Organisasi</h3>
            <hr className="custom-horizontal-line mx-auto" />
            <p>
              Sebagai salah satu lembaga pelatihan kerja kami memiliki waktu
              cukup singkat untuk memberikan pembekalan berupa pendidikan
              mental, kedisiplinan, bahasa dan budaya kerja kepada siswa.
              Mengetahui hal ini kami membentuk sebuah tim yang diisi oleh para
              professional untuk mendapatkan hasil maksimal dari pendidikan yang
              kami berikan kepada siswa-siswi LPK Asta Karya. Para professional
              yang mendidik mental dan kedisiplinan ini adalah staff dengan
              pengalaman hidup di Jepang minimal 1 (satu) tahun, sedangkan staff
              yang mendidik bahasa dan budaya kerja Jepang adalah lulusan yang
              bersetifikasi Bahasa Jepang minimal N4 (Level 4).
            </p>
            <p>
              Tim ini merupakan gambaran lembaga pelatihan kerja yang modern,
              dimana LPK harus mampu menciptakan sebuah sistem yang mampu
              mengakomodir semua aspek dan masukan dari semua unitnya
              berbasiskan demokrasi, sekaligus berjalan beriringan dengan
              manajemen modern untuk mendapatkan hasil lulusan yang berkualitas.
            </p>
          </Col>
          <Col xs="12">
            {!isMediumDevice ? (
              <img
                src={StrukturOrganisasi}
                alt="struktur-organisasi"
                className="my-5"
                style={{
                  height: "25rem",
                  width: "100%",
                  objectFit: "cover",
                }}
              />
            ) : (
              <a href={StrukturOrganisasi} target="_blank" rel="noreferrer">
                <strong>Selengkapnya</strong>
              </a>
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default IniKami;
