import React from "react";
import { Col, Container, Row } from "reactstrap";

import "./index.scss";

import PelatihanHeadImg from "assets/pelatihan/img-training-center-01.jpg";
import TrainingCenterImg from "assets/pelatihan/img-training-center-2-01.jpg";
import OfficeCenterImg from "assets/pelatihan/img-training-ceter-3-01.jpg";

const bangunan1Spec = [
  "1 Ruang Kerja",
  "4 Ruang Kelas",
  "1 Ruang Tamu",
  "5 Ruang Tidur",
  "6 Shower Room",
  "2 Kamar Mandi (Toilet)",
  "1 Dapur dan Ruang Makan",
];

const bangunan2Spec = [
  "1 Ruang Kerja",
  "2 Ruang Kelas",
  "1 Ruang Tamu",
  "3 Ruang Tidur",
  "3 Kamar Mandi",
  "1 Dapur dan Ruang Makan",
];

const officeSpec = [
  "1 Ruang Kerja",
  "5 Ruang Kelas",
  "5 Ruang Tidur",
  "3 kamar Mandi",
  "1 Pantry",
  "1 Dapur",
];

const bulanPelatihan = [
  {
    title: "Bulan Pertama",
    points: [
      "Review Minna no Nihongo I",
      "Minna no Nihongo I (Bab 1 - Bab 15)",
      "Hafalan Kotoba",
    ],
  },
  {
    title: "Bulan Kedua",
    points: [
      "Minna no Nihongo I (Bab 16 - Bab 25)",
      "Minna no Nihongo II (Bab 1 - Bab 5)",
      "Irodori A2-1 (Bab 1 - Bab 18)",
      "Hafalan Kotoba",
    ],
  },
  {
    title: "Bulan Ketiga",
    points: [
      "Minna no Nihongo II (Bab 6 - Bab 20)",
      "Irodori A2-2 (Bab 1 - Bab 18)",
      "Budaya Jepang",
      "Hafalan Kotoba",
    ],
  },
  {
    title: "Bulan Keempat",
    points: [
      "Review Minna no Nihogo 1 & 2",
      "Review Irodori A2-1 dan A2-2",
      "Budaya Jepang",
    ],
  },
];

const jadwalKegiatanWeekday = [
  {
    jam: "05.00 - 06.00",
    desc: "Bangun dan persiapan untuk piket",
  },
  {
    jam: "06.00 - 07.00",
    desc: "Piket dan olah raga",
  },
  {
    jam: "07.00 - 08.00",
    desc: "Persiapan masuk kelas dan briefing pagi",
  },
  {
    jam: "08.00 - 12.00",
    desc: "Pembelajaran Budaya dan Bahasa Jepang",
  },
  {
    jam: "12.00 - 13.00",
    desc: "Istirahat siang",
  },
  {
    jam: "13.00 - 16.00",
    desc: "Pembelajaran Budaya dan Bahasa Jepang",
  },
  {
    jam: "16.00 - 17.00",
    desc: "Piket sore dan briefing sore",
  },
  {
    jam: "17.00 ~",
    desc: "Istirahat dan mengerjakan tugas",
  },
];

const jadwalKegiatanWeekend = [
  {
    jam: "05.00 - 06.00",
    desc: "Bangun dan persiapan untuk piket",
  },
  {
    jam: "06.00 - 07.00",
    desc: "Piket dan olah raga",
  },
  {
    jam: "07.00 - 08.00",
    desc: "Persiapan masuk kelas dan briefing pagi",
  },
  {
    jam: "08.00 - 12.00",
    desc: "Nonton bersama film Jepang",
  },
  {
    jam: "12.00 ~",
    desc: "Istirahat",
  },
];

const Pelatihan = (props) => {
  const { isMediumDevice } = props;

  return (
    <>
      <img
        src={PelatihanHeadImg}
        alt="banner"
        style={{
          height: isMediumDevice ? "10rem" : "30rem",
          width: "100%",
          objectFit: "cover",
          objectPosition: "top",
        }}
        className="mb-5"
      />
      <Container>
        <Row className="mb-5">
          <Col xl="6" xs="12" className={isMediumDevice ? "mb-3" : ""}>
            <img
              src={TrainingCenterImg}
              alt="banner"
              style={{
                height: "100%",
                width: "100%",
                objectFit: "cover",
              }}
            />
          </Col>
          <Col xl="6" xs="12">
            <h3 className={isMediumDevice ? "text-center" : ""}>
              Training Center Bandung
            </h3>
            <hr
              className={`custom-horizontal-line ${isMediumDevice ? "mx-auto" : ""}`}
            />
            <p>
              Dikarenakan kapasitas yang terbatas di LPK Asta Karya Jakarta maka
              kami membuka khusus training center yang berlokasi di Kota
              Bandung, Indonesia pada November 2022. Terdiri dari dua bangunan
              dengan spesifikasi sebagai berikut:
            </p>
            <p>Bangunan 1 memiliki kapasitas 45 orang, memiliki :</p>
            <Row className="mb-3">
              <Col xl="3" xs="12">
                {bangunan1Spec
                  .slice(0, Math.round(bangunan1Spec.length / 2))
                  .map((val, index) => (
                    <p key={index} className="mb-0">
                      {val}
                    </p>
                  ))}
              </Col>
              <Col xl="9" xs="12">
                {bangunan1Spec
                  .slice(
                    Math.round(bangunan1Spec.length / 2),
                    bangunan1Spec.length,
                  )
                  .map((val, index) => (
                    <p key={index} className="mb-0">
                      {val}
                    </p>
                  ))}
              </Col>
            </Row>
            <p>Bangunan 2 memiliki kapasitas 30 orang, memiliki :</p>
            <Row className="mb-3">
              <Col xl="3" xs="12">
                {bangunan2Spec
                  .slice(0, Math.round(bangunan2Spec.length / 2))
                  .map((val, index) => (
                    <p key={index} className="mb-0">
                      {val}
                    </p>
                  ))}
              </Col>
              <Col xl="9" xs="12">
                {bangunan2Spec
                  .slice(
                    Math.round(bangunan2Spec.length / 2),
                    bangunan2Spec.length,
                  )
                  .map((val, index) => (
                    <p key={index} className="mb-0">
                      {val}
                    </p>
                  ))}
              </Col>
            </Row>
            <p>
              Alamat lengkap : Griya Cigadung Baru No.A2, Jl. Cigadung Raya
              Timur, Cigadung, Cibeunying Kaler, Kota Bandung, Jawa Barat, 40191
            </p>

            <a
              href="https://maps.app.goo.gl/eaNdkCTHx41a2Dqp9"
              target="_blank"
              rel="noreferrer"
            >
              <strong>Lihat Lokasi di Maps</strong>
            </a>
          </Col>
        </Row>
        <Row className="mb-5">
          <Col xl="6" xs="12" className={isMediumDevice ? "mb-3" : ""}>
            <img
              src={OfficeCenterImg}
              alt="banner"
              style={{
                height: "100%",
                width: "100%",
                objectFit: "cover",
              }}
            />
          </Col>
          <Col xl="6" xs="12">
            <h3 className={isMediumDevice ? "text-center" : ""}>
              Office & Training Center
            </h3>
            <hr
              className={`custom-horizontal-line ${isMediumDevice ? "mx-auto" : ""}`}
            />
            <p>
              LPK Asta Karya Jakarta adalah bangunan pertama LPK Asta Karya.
              Saat ini LPK Asta Karya Jakarta lebih fokus terhadap perekrutan,
              pengurusan doku- men, penerimaan tamu, tempat wawancara siswa dan
              juga merangkap sebagai training center.
            </p>
            <p>
              LPK Asta Karya Jakarta merupakan 1 bangunan ruko 3 lantai dengan
              kapasitas total 40 orang, memiliki :
            </p>
            <Row className="mb-3">
              <Col xl="3" xs="12">
                {officeSpec
                  .slice(0, Math.round(officeSpec.length / 2))
                  .map((val, index) => (
                    <p key={index} className="mb-0">
                      {val}
                    </p>
                  ))}
              </Col>
              <Col xl="9" xs="12">
                {officeSpec
                  .slice(Math.round(officeSpec.length / 2), officeSpec.length)
                  .map((val, index) => (
                    <p key={index} className="mb-0">
                      {val}
                    </p>
                  ))}
              </Col>
            </Row>
            <p>
              Jl. Asem Baris Raya No.52 1, RT.1/RW.5, Kb. Baru, Jakarta, Kota
              Jakarta Selatan, Daerah Khusus Ibukota Jakarta 12830
            </p>
            <a
              href="https://maps.app.goo.gl/eaNdkCTHx41a2Dqp9"
              target="_blank"
              rel="noreferrer"
            >
              <strong>Lihat Lokasi di Maps</strong>
            </a>
          </Col>
        </Row>
        <Row className="mb-5">
          <Col xs="12">
            <h3 className="text-center">Sistem Pelatihan</h3>
            <hr className="custom-horizontal-line mx-auto" />
            <p>
              Pelatihan atau diklat di LPK Asta Karya berlangsung 3 (tiga)
              sampai 4 (empat) bulan. Siswa akan tinggal bersama dalam 1 (satu)
              asrama (asrama laki-laki dan perempuan terpisah) selama menjalani
              masa diklat. Selama masa diklat siswa akan diberikan pelatihan
              mental, kedisiplinan, budaya dan Bahasa Jepang.
            </p>
          </Col>
          {bulanPelatihan.map((val, index) => (
            <Col
              key={index}
              xl="3"
              className={`px-0 ${index === bulanPelatihan.length - 1 && !isMediumDevice ? "border-end" : ""} ${isMediumDevice ? "mb-3" : "border-start border-2 border-danger-subtle"}`}
            >
              <p className="mb-0 text-center">{val.title}</p>
              <div
                className={`w-100 ${index === bulanPelatihan.length - 1 ? "custom-bg-stripes" : "bg-warning bg-opacity-75"}`}
                style={{ height: "2rem" }}
              />
              <div className="px-2">
                {val.points.map((val, index) => (
                  <p key={index} className="mb-0">
                    {val}
                  </p>
                ))}
              </div>
            </Col>
          ))}
        </Row>
        <Row className="mb-3 jadwal-kegiatan">
          <Col xs="12">
            <h3 className="text-center mb-4">Jadwal Umum Kegiatan Pelatihan</h3>
          </Col>
          <Col xl="6" xs="12" className={isMediumDevice ? "mb-3" : ""}>
            <Row>
              <Col xs="4" className="text-center pe-0">
                <p className="mb-1 bg-primary bg-opacity-25 py-3">
                  <strong>Senin - Jum'at</strong>
                </p>
                <p className="mb-1 bg-primary bg-opacity-25 py-3">
                  <strong>Waktu</strong>
                </p>
              </Col>
              <Col xs="8" className="mb-1 ps-1">
                <div
                  className="d-flex align-items-center w-100 bg-primary bg-opacity-25"
                  style={{ height: "100%" }}
                >
                  <p className="mb-0 ms-3">
                    <strong>Kegiatan</strong>
                  </p>
                </div>
              </Col>
              {jadwalKegiatanWeekday.map((val, index) => (
                <React.Fragment key={index}>
                  <Col xs="4" className="pe-0 text-center">
                    <p
                      className={`mb-1 bg-secondary ${index % 2 === 1 ? "bg-opacity-25" : "bg-opacity-10"}`}
                    >
                      {val.jam}
                    </p>
                  </Col>
                  <Col xs="8" className="ps-1">
                    <p
                      className={`mb-1 ps-3 bg-secondary ${index % 2 === 1 ? "bg-opacity-25" : "bg-opacity-10"}`}
                    >
                      {val.desc}
                    </p>
                  </Col>
                </React.Fragment>
              ))}
            </Row>
          </Col>
          <Col xl="6" xs="12" className={isMediumDevice ? "mb-3" : ""}>
            <Row>
              <Col xs="4" className="text-center pe-0">
                <p className="mb-1 bg-primary bg-opacity-25 py-3">
                  <strong>Sabtu</strong>
                </p>
                <p className="mb-1 bg-primary bg-opacity-25 py-3">
                  <strong>Waktu</strong>
                </p>
              </Col>
              <Col xs="8" className="mb-1 ps-1">
                <div
                  className="d-flex align-items-center w-100 bg-primary bg-opacity-25"
                  style={{ height: "100%" }}
                >
                  <p className="mb-0 ms-3">
                    <strong>Kegiatan</strong>
                  </p>
                </div>
              </Col>
              {jadwalKegiatanWeekend.map((val, index) => (
                <React.Fragment key={index}>
                  <Col xs="4" className="pe-0 text-center">
                    <p
                      className={`mb-1 bg-secondary ${index % 2 === 1 ? "bg-opacity-25" : "bg-opacity-10"}`}
                    >
                      {val.jam}
                    </p>
                  </Col>
                  <Col xs="8" className="ps-1">
                    <p
                      className={`mb-1 ps-3 bg-secondary ${index % 2 === 1 ? "bg-opacity-25" : "bg-opacity-10"}`}
                    >
                      {val.desc}
                    </p>
                  </Col>
                </React.Fragment>
              ))}
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Pelatihan;
