import React from "react";
import {
  Button,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";

import KontakKamiImg from "assets/kontak-kami/tilpuniniya-01.svg";

const KontakKami = (props) => {
  const { isMediumDevice } = props;

  return (
    <Container>
      <Row className="my-5">
        {!isMediumDevice && (
          <Col xl="6">
            <img src={KontakKamiImg} alt="Kontak Kami" className="img-fluid" />
          </Col>
        )}
        <Col xl="6" xs="12">
          <h3>Informasi Lebih Lanjut</h3>
          <hr className="custom-horizontal-line" />
          <form
            action="mailto:promotion.astakarya@gmail.com"
            method="post"
            enctype="text/plain"
          >
            <FormGroup>
              <Label for="nama">Nama</Label>
              <Input id="nama" name="nama" placeholder="Nama *" type="text" />
            </FormGroup>
            <FormGroup>
              <Label for="email">Email</Label>
              <Input
                id="email"
                name="email"
                placeholder="Email *"
                type="email"
              />
            </FormGroup>
            <FormGroup>
              <Label for="subjek">Subjek</Label>
              <Input
                id="subjek"
                name="subjek"
                placeholder="Subjek *"
                type="text"
              />
            </FormGroup>
            <FormGroup>
              <Label for="pesan">Pesan</Label>
              <Input
                id="pesan"
                name="pesan"
                placeholder="Pesan *"
                type="textarea"
                rows="5"
              />
            </FormGroup>
            <Button type="submit">Kirim</Button>
          </form>
        </Col>
      </Row>
    </Container>
  );
};

export default KontakKami;
