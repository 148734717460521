import React from "react";
import { Col, Container, Row } from "reactstrap";

import "./index.scss";

import IconPengolahanMakanan from "assets/icon-keahlian/icon-pengolahan-makanan-01.svg";
import IconKonstruksiBangunan from "assets/icon-keahlian/icon-konstruksi-01.svg";
import IconCareWorker from "assets/icon-keahlian/icon-care-worker.svg";
import IconMachinery from "assets/icon-keahlian/icon-machinery.svg";
import IconIndustrialPackaging from "assets/icon-keahlian/icon-industrial-packing-01.svg";
import IconTekstil from "assets/icon-keahlian/icon-textile-01.svg";
import IconPengelasan from "assets/icon-keahlian/icon-pengelasan.svg";

import IconPerekrutan from "assets/icon-alur-pemagangan-snk/icon-2-perekrutan.svg";
import IconPelatihan from "assets/icon-alur-pemagangan-snk/icon-2-pelatihan.svg";
import IconDokumen from "assets/icon-alur-pemagangan-snk/icon-2-dokumen.svg";
import IconMagang from "assets/icon-alur-pemagangan-snk/icon-2-magang.svg";
import IconPascaMagang from "assets/icon-alur-pemagangan-snk/icon-2-pasca-magang.svg";

import JapanMaps from "assets/japan-maps.svg";

import ProgramHeadImg from "assets/program/img-program-1-01.jpg";
import AlurPemaganganImg from "assets/program/img-program-alur-01.jpg";

const keahlianMagangDanPenempatan = [
  {
    label: "Pengolahan Makanan",
    icons: IconPengolahanMakanan,
  },
  {
    label: "Konstruksi Bangunan",
    icons: IconKonstruksiBangunan,
  },
  {
    label: "Care Worker",
    icons: IconCareWorker,
  },
  {
    label: "Machinery",
    icons: IconMachinery,
  },
  {
    label: "Industrial Packaging",
    icons: IconIndustrialPackaging,
  },
  {
    label: "Textile",
    icons: IconTekstil,
  },
  {
    label: "Pengelasan",
    icons: IconPengelasan,
  },
];

const alurPemagangan = [
  {
    title: "Perekrutan",
    desc: "Pihak LPK Asta Karya bekerjasama dengan pihak Accepting Organization (AO) di Jepang untuk melakukan perekrutan siswa yang nantinya akan di wawancara oleh pihak Perusahaan",
    icons: IconPerekrutan,
  },
  {
    title: "Pelatihan",
    desc: "LPK Asta Karya mengadakan diklat berupa pelatihan Budaya dan Bahasa Jepang serta membangun mental kepada siswa selama 3 (tiga) sampai 4 (empat) bulan di Indonesia, Bandung (tempat pelatihan LPK Asta Karya)",
    icons: IconPelatihan,
  },
  {
    title: "Dokumen",
    desc: "Pengurusan dokumen siswa untuk keberangkatan diproses selama siswa menjalani diklat di LPK Asta Karya.",
    icons: IconDokumen,
  },
  {
    title: "Magang",
    desc: "Pengurusan dokumen siswa untuk keberangkatan diproses selama siswa menjalani diklat di LPK Asta Karya.",
    icons: IconMagang,
  },
  {
    title: "Pasca Magang",
    desc: "Pengurusan dokumen siswa untuk keberangkatan diproses selama siswa menjalani diklat di LPK Asta Karya.",
    icons: IconPascaMagang,
  },
];

const syaratUmumPria = [
  "Kondisi mata normal (tidak berkaca mata)* dan tidak buta warna (wajib melampirkan hasil tes buta warna)",
  "Tidak memiliki penyakit menular atau penyakit dalam yang berbahaya (wajib melampirkan hasil medical check up)",
  "Tidak memiliki tato dan atau bekas tato",
  "Tidak memiliki tindik dan atau bekas tindik",
  "Tinggi badan minimal 160cm**",
  "Berat badan ideal, minimal 56kg",
  "Usia 18-30 tahun***",
];

const syaratUmumWanita = [
  "Kondisi mata normal (tidak berkaca mata)* dan tidak buta warna (wajib melampirkan hasil tes buta warna)",
  "Tidak memiliki penyakit menular atau penyakit dalam yang berbahaya (wajib melampirkan hasil medical check up)",
  "Tidak memiliki tato dan atau bekas tato",
  "-",
  "Tinggi badan minimal 150cm**",
  "Berat badan ideal, minimal 50kg",
  "Usia 18-28 tahun***",
];

const syaratUmumDisc = [
  "*) Tergantung pihak perusahaan, ada kalanya tidak bermasalah menggunakan kaca mata",
  "**) Tinggi badan tergantung pada perusahaan",
  "***) Usia tergantung permintaan dari pihak perusahaan",
];

const syaratDokumen = [
  "Memiliki kelengkapan data pribadi (KTP, Akta Kelahiran, Kartu Keluarga dan Ijazah Terakhir), dan Paspor (jika sudah memiliki Paspor)",
  "Pendidikan minimal Sekolah Menengah Atas (SMA) sederajat (Madrash Aliyah (MA) / Sekolah Menengah Kejuruan (SMK) / Madrasah Aliyah Kejuruan (MAK) / Paket C",
  "Memiliki basic Bahasa Jepang*",
  "Memiliki sertifikasi keterampilan khusus**",
];

const syaratDokumenDisc = [
  "*) Basic Bahasa Jepang yang dimaksudkan disini adalah peserta sudah menguasai huruf Hiragana dan Katakana. Jika mengacu pada buku Minna no Nihon-go 1, peserta sudah menempuh pembelajaran sampai Bab 15 dan sudah lancar untuk Jikoshoukai (memperkenalkan diri)",
  "**) Sertifikasi khusus ini bergantung pada perusahaan dan hanya beberapa jenis kerja saja",
];

const Program = (props) => {
  const { isMediumDevice, isLargeDevice } = props;

  return (
    <>
      <Container className="mt-3">
        <Row className="mb-5">
          <Col xl="6" xs="12" className={isMediumDevice ? "mb-3" : ""}>
            {isMediumDevice ? (
              <img
                src={ProgramHeadImg}
                alt="banner"
                style={{
                  width: "100%",
                  height: "40vh",
                  objectFit: "cover",
                }}
              />
            ) : isLargeDevice ? (
              <img
                src={ProgramHeadImg}
                alt="banner"
                style={{
                  width: "100%",
                  height: "30vh",
                  objectFit: "cover",
                }}
              />
            ) : (
              <img
                src={ProgramHeadImg}
                alt="banner"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
              />
            )}
          </Col>
          <Col xl="6" xs="12">
            <h3
              className={`${isLargeDevice ? "mt-3" : ""} ${isMediumDevice ? "text-center" : ""}`}
            >
              Program Magang
            </h3>
            <hr
              className={`custom-horizontal-line ${isMediumDevice ? "mx-auto" : ""}`}
            />
            <p>
              Pemagangan Luar Negeri adalah bagian dari sistem pelatihan kerja
              yang diselenggarakan secara utuh dan terpadu di Indonesia dan di
              luar negeri oleh lembaga pelatihan kerja atau perusahaan atau
              instansi pemerintah atau lembaga pendidikan di bawah bimbingan dan
              pengawasan instruktur dan/atau pekerja yang lebih berpengalaman,
              dalam proses produksi barang dan/atau jasa dalam rangka menguasai
              keterampilan atau keahlian tertentu (Permenakertrans Nomor : Per.
              08/Men/V/2008).
            </p>
            <p>
              Dalam saat ini kami sebagai lembaga pengirim hanya bekerjasama
              dengan Negara Jepang sebagai negara tujuan untuk Magang.
            </p>
            <p>
              Pelaksanaan program pemagangan di Jepang dilaksanakan selama 3
              (tiga) tahun dan dapat diperpanjang sampai 5 (lima) tahun, yang
              terdiri dari be- berapa tahap, yaitu:
            </p>
            <ol type="a">
              <li>
                Program pemagangan bulan pertama di Jepang disebut masa training
                atau masa Jisshusei, yang artinya "masa berlatih sambil
                bekerja". Peserta masih berlatih penyesuaian di training center.
              </li>
              <li>
                Bulan ke-2 sampai dengan bulan ke-12, akan dievaluasi
                kompetensinya, dan untuk dapat melanjutkan ke tahap berikutnya,
                peserta harus lulus ujian yang diadakan pada akhir tahun
                pertama.
              </li>
              <li>
                Program pemagangan bulan kedua sampai dengan tahun ketiga
                disebut technical intern training atau disebut juga masa
                Jisshusei. Pada masa Jisshusei ini peserta dilindungi oleh
                Undang-Undang Perburuhan di Jepang, dan diperbolehkan kerja
                lembur.
              </li>
              <li>
                Program pemagangan dapat diperpanjang menjadi 5 tahun, apabila
                perusahaan dan peserta sepakat untuk memperpanjang masa praktek
                kerja.
              </li>
            </ol>
          </Col>
        </Row>
        <Row className={`mb-5 ${!isMediumDevice ? "row-with-maps" : ""}`}>
          <Col xl="6" xs="12">
            <h3 className={isMediumDevice ? "text-center" : ""}>
              Jurusan Keahlian Magang & Penempatan
            </h3>
            <hr
              className={`custom-horizontal-line ${isMediumDevice ? "mx-auto" : ""}`}
            />
            <p>
              Pada data berikut ini adalah jurusan keahlian magang dan
              penempatan magang yang sudah dan sedang berlangsung sampai saat
              ini. Data sewaktu-waktu dapat berubah sesuai dengan data terkini.
            </p>
            <p>
              Jurusan keahlian pemagangan terbanyak saat ini adalah pada jurusan
              pengolahan makanan dengan jumlah total siswa 163 siswa. Diikuti
              dengan konstruksi bangunan di 121 siswa dan urutan ketiga jatuh
              pada care worker dengan 43 siswa.
            </p>
            <div>
              {keahlianMagangDanPenempatan.map((val, index) => (
                <div key={index} className="d-flex align-items-center mb-3">
                  <img
                    src={val.icons}
                    alt={`icon-${index}`}
                    height={"48px"}
                    width={"48px"}
                  />
                  <p className="mb-0 ms-3">
                    <strong>{val.label}</strong>
                  </p>
                </div>
              ))}
            </div>
          </Col>
          {isMediumDevice && (
            <Col xs="12">
              <a href={JapanMaps} target="_blank" rel="noreferrer">
                <strong>Peta Penempatan</strong>
              </a>
            </Col>
          )}
        </Row>
        <Row className="mb-3">
          <Col
            xl={{
              offset: 7,
              order: 2,
              size: 5,
            }}
            xs="12"
          >
            <h3>Alur Pemagangan</h3>
          </Col>
        </Row>
        <Row>
          <Col xl="7" xs="12">
            {alurPemagangan.map((val, index) => (
              <div
                key={index}
                className={`mb-3 ${index < alurPemagangan.length - 1 ? "border-bottom border-black" : ""}`}
              >
                <div className="d-flex align-items-center mb-2">
                  <img
                    src={val.icons}
                    alt={`icon-${index}`}
                    height={"48px"}
                    width={"48px"}
                    className="me-3"
                  />
                  <div>
                    <h4 className="mb-0">
                      <strong>0{index + 1}</strong>
                    </h4>
                    <h4 className="mb-0">
                      <strong>{val.title}</strong>
                    </h4>
                    <hr
                      className={`mt-2 mb-0 custom-horizontal-line`}
                      style={{
                        width: "3rem",
                      }}
                    />
                  </div>
                </div>
                <p>{val.desc}</p>
              </div>
            ))}
          </Col>
          {!isMediumDevice && (
            <Col xl="5">
              <img
                src={AlurPemaganganImg}
                alt="banner"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
              />
            </Col>
          )}
        </Row>
        <Row className="my-3">
          <Col
            xl={{
              offset: 7,
              order: 2,
              size: 5,
            }}
            xs="12"
          >
            {/* <p>
              <strong>Selengkapnya</strong>
            </p> */}
          </Col>
        </Row>
        <Row>
          <Col xs="12">
            <h3 className="text-center">Persyaratan Umum</h3>
            <hr
              className={`custom-horizontal-line mx-auto`}
              style={{ width: "10%" }}
            />
            <p>
              Untuk dapat mengikuti program magang ke Jepang, peserta wajib
              memenuhi persyaratan yang diberikan oleh pihak perusahaan. Setiap
              perusahaan memiliki persyaratan yang berbeda-beda tergantung dari
              permintaan dan kebutuhan. Selain persyaratan dari perusahaan
              adapula persyaratan umum dari kami di bawah ini :
            </p>
          </Col>
        </Row>
        <Row className="p-1 border rounded mb-3">
          <Col
            xs="12"
            className="mb-1 text-center py-3 bg-primary bg-opacity-25 rounded-top"
          >
            <p className="mb-0">
              <strong>Syarat Umum Pemagangan ke Jepang (Fisik)</strong>
            </p>
          </Col>
          <Col xs="6" className="text-center ps-0 pe-1">
            <div className="bg-primary bg-opacity-25 h-100 py-3">
              <p className="mb-0">
                <strong>Laki-Laki</strong>
              </p>
            </div>
          </Col>
          <Col xs="6" className="text-center ps-1 pe-0">
            <div className="bg-primary bg-opacity-25 h-100 py-3">
              <p className="mb-0">
                <strong>Perempuan</strong>
              </p>
            </div>
          </Col>
          {syaratUmumPria.map((val, index) => {
            if (val === syaratUmumWanita[index]) {
              return (
                <Col
                  xs="12"
                  className={`text-center p-1 bg-secondary mt-1 ${index % 2 === 1 ? "bg-opacity-25" : "bg-opacity-10"}`}
                  key={index}
                >
                  {val}
                </Col>
              );
            } else {
              return (
                <React.Fragment key={index}>
                  <Col xs="6" className={`text-center mt-1 ps-0 pe-1`}>
                    <div
                      className={`bg-secondary p-1 h-100 d-flex align-items-center justify-content-center ${index % 2 === 1 ? "bg-opacity-25" : "bg-opacity-10"}`}
                    >
                      {val}
                    </div>
                  </Col>
                  <Col xs="6" className={`text-center mt-1 pe-0 ps-1`}>
                    <div
                      className={`bg-secondary p-1 h-100 d-flex align-items-center justify-content-center ${index % 2 === 1 ? "bg-opacity-25" : "bg-opacity-10"}`}
                    >
                      {syaratUmumWanita[index]}
                    </div>
                  </Col>
                </React.Fragment>
              );
            }
          })}
          <Col
            xs="12"
            className={`text-center bg-secondary rounded-bottom mt-1 p-1 ${(syaratUmumPria.length + 1) % 2 !== 1 ? "bg-opacity-25" : "bg-opacity-10"}`}
          >
            {syaratUmumDisc.map((val, index) => (
              <p className="mb-0" key={index}>
                <i>{val}</i>
              </p>
            ))}
          </Col>
        </Row>
        <Row className="p-1 border rounded mb-3">
          <Col
            xs="12"
            className="mb-1 text-center py-3 bg-warning bg-opacity-25 rounded-top"
          >
            <p className="mb-0">
              <strong>Syarat Umum Pemagangan ke Jepang (Dokumen)</strong>
            </p>
          </Col>
          <Col xs="6" className="text-center ps-0 pe-1">
            <div className="bg-warning bg-opacity-25 h-100 py-3">
              <p className="mb-0">
                <strong>Laki-Laki</strong>
              </p>
            </div>
          </Col>
          <Col xs="6" className="text-center ps-1 pe-0">
            <div className="bg-warning bg-opacity-25 h-100 py-3">
              <p className="mb-0">
                <strong>Perempuan</strong>
              </p>
            </div>
          </Col>
          {syaratDokumen.map((val, index) => (
            <Col
              xs="12"
              className={`text-center p-1 bg-secondary mt-1 ${index % 2 === 1 ? "bg-opacity-25" : "bg-opacity-10"}`}
              key={index}
            >
              {val}
            </Col>
          ))}
          <Col
            xs="12"
            className={`text-center bg-secondary rounded-bottom mt-1 p-1 ${(syaratDokumen.length + 1) % 2 !== 1 ? "bg-opacity-25" : "bg-opacity-10"}`}
          >
            {syaratDokumenDisc.map((val, index) => (
              <p className="mb-0" key={index}>
                <i>{val}</i>
              </p>
            ))}
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Program;
