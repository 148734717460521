import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { useEffect, useState } from "react";

import "./App.scss";

import NavigationBar from "./components/navigationBar";
import Footer from "./components/footer";
import Home from "./pages/Home";
import NotFound from "pages/NotFound";
import IniKami from "pages/IniKami";
import Program from "pages/Program";
import Pelatihan from "pages/Pelatihan";
import Media from "pages/Media";
import { Spinner } from "reactstrap";
import KontakKami from "pages/KontakKami";

function App() {
  // Width listener region
  const useWindowWidth = () => {
    const [width, setWidth] = useState(window.innerWidth);

    useEffect(() => {
      const handleResize = () => setWidth(window.innerWidth);

      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }, []);

    return width;
  };

  const width = useWindowWidth();

  const [isMediumDevice, setIsMediumDevice] = useState(width < 992);
  const [isLargeDevice, setIsLargeDevice] = useState(
    width >= 992 && width < 1200,
  );

  useEffect(() => {
    if (width > 992) {
      setIsMediumDevice(false);
    } else {
      setIsMediumDevice(true);
    }

    if (width >= 992 && width < 1200) {
      setIsLargeDevice(true);
    } else {
      setIsLargeDevice(false);
    }
  }, [width]);
  // End width listener region

  // Scroll listener region
  const [scrollPosition, setScrollPosition] = useState(0);

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  // End listener region

  // Start translation region
  const [lang, setLang] = useState(localStorage.getItem("lang"));

  useEffect(() => {
    // if (!lang) {
    setLang("id");
    localStorage.setItem("lang", "id");
    // }
  }, [lang]);
  // End translation region

  const router = createBrowserRouter([
    {
      path: "/",
      element: (
        <Home
          isMediumDevice={isMediumDevice}
          isLargeDevice={isLargeDevice}
          lang={lang}
        />
      ),
    },
    {
      path: "/ini-kami",
      element: (
        <IniKami
          isMediumDevice={isMediumDevice}
          isLargeDevice={isLargeDevice}
          lang={lang}
        />
      ),
    },
    {
      path: "/program",
      element: (
        <Program
          isMediumDevice={isMediumDevice}
          isLargeDevice={isLargeDevice}
          lang={lang}
        />
      ),
    },
    {
      path: "/pelatihan",
      element: (
        <Pelatihan
          isMediumDevice={isMediumDevice}
          isLargeDevice={isLargeDevice}
          lang={lang}
        />
      ),
    },
    {
      path: "/media",
      element: (
        <Media
          isMediumDevice={isMediumDevice}
          isLargeDevice={isLargeDevice}
          lang={lang}
        />
      ),
    },
    {
      path: "/kontak-kami",
      element: (
        <KontakKami
          isMediumDevice={isMediumDevice}
          isLargeDevice={isLargeDevice}
          lang={lang}
        />
      ),
    },
    {
      path: "*",
      element: <NotFound />,
    },
  ]);

  return (
    <>
      {!lang ? (
        <div
          className="d-flex align-items-center justify-content-center"
          style={{ width: "100vw", height: "100vh" }}
        >
          <Spinner />
        </div>
      ) : (
        <div className="App">
          <NavigationBar
            scrollPosition={scrollPosition}
            setLang={setLang}
            lang={lang}
          />

          <div className="page-router">
            <RouterProvider router={router} />
          </div>

          <Footer lang={lang} />
        </div>
      )}
    </>
  );
}

export default App;
