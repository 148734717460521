import React from "react";
import { Col, Container, Row } from "reactstrap";

const NotFound = () => {
  return (
    <Container>
      <Row>
        <Col xs="12">
          <h1 className="my-5 text-center">
            <strong>404 - Not Found</strong>
          </h1>
        </Col>
      </Row>
    </Container>
  );
};

export default NotFound;
