import React from "react";
import { Col, Container, Row } from "reactstrap";

import LogoWhite from "assets/logo-white.png";

import FacebookLogo from "assets/fb.svg";
import InstagramLogo from "assets/ig.svg";

import "./index.scss";

const Footer = () => {
  return (
    <footer className="bg-primary text-white">
      <Container>
        <Row>
          <Col>
            <img
              src={LogoWhite}
              alt="logo-white"
              style={{ height: "5rem" }}
              className="my-3"
            />
          </Col>
        </Row>
        <Row className="pb-3">
          <Col xl="4" className="legalitas-links">
            <h4 className="mb-3">Legalitas</h4>
            <a
              href="https://binalattas.kemnaker.go.id/pemagangan/perizinan/so/datalembaga/tbl_syaratijinbarulist.php?cmd=reset"
              target="_blank"
              rel="noreferrer"
              className="text-white"
            >
              <p className="mb-0">KEMNAKER</p>
            </a>
            <a
              href="https://ap2ln.org/user/promotion.astakaryagmail-com/"
              target="_blank"
              rel="noreferrer"
              className="text-white"
            >
              <p className="mb-0">AP2LN</p>
            </a>
            <a
              href="https://www.jitco.or.jp/en/regulation/send/indonesia/index.html"
              target="_blank"
              rel="noreferrer"
              className="text-white"
            >
              <p className="mb-0">JITCO</p>
            </a>
            <a
              href="https://www.otit.go.jp/files/user/docs/200520-2.pdf"
              target="_blank"
              rel="noreferrer"
              className="text-white"
            >
              <p>OTIT</p>
            </a>

            <h4 className="mb-3">Social Media</h4>
            <div className="d-flex align-items-center justify-content-start mb-4">
              <a
                href="https://www.facebook.com/lpkastakarya"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={FacebookLogo}
                  alt="facebook"
                  className="me-2 cursor-pointer"
                  style={{ width: "2rem" }}
                />
              </a>
              <a
                href="https://www.instagram.com/lpkastakarya/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={InstagramLogo}
                  alt="instagram"
                  className="cursor-pointer"
                  style={{ width: "2rem" }}
                />
              </a>
            </div>

            <h4 className="mb-2">Email</h4>
            <p>promotion.astakarya@gmail.com</p>
          </Col>
          <Col xl="4">
            <h4 className="mb-3">Kantor</h4>
            <p>
              <strong>Kantor dokumen dan perekrutan</strong>
            </p>
            <p>
              Jl. Asem Baris Raya No. 52, RT 1 / RW 5, Kb. Baru, Jakarta, Kota
              Jakarta Selatan, Daerah Khusus Ibukota Jakarta 12830
            </p>
            <p>
              <strong>Kantor utama dan pelatihan</strong>
            </p>
            <p>
              Komplek Griya Cigadung Baru No. A2, Jl. Cigadung Raya Timur,
              Cigadung, Cibeunying Kaler, Bandung City, West Java 40191
            </p>
          </Col>
          <Col
            xl={{
              offset: 1,
              size: 3,
            }}
            className="legalitas-links"
          >
            <h4 className="mb-3">Berkenalan</h4>
            <a
              href="mailto:promotion.astakarya@gmail.com"
              className="text-white"
              rel="noreferrer"
            >
              <p className="mb-0">Kontak Kami</p>
            </a>
            <a
              href="https://maps.app.goo.gl/eaNdkCTHx41a2Dqp9"
              target="_blank"
              rel="noreferrer"
              className="text-white"
            >
              <p>Map dan Petunjuk Arah</p>
            </a>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3966.283199299892!2d106.85630787508133!3d-6.226342460978483!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69f3d1c4e66a01%3A0x5d3b693265971dbf!2sLPK%20Asta%20Karya!5e0!3m2!1sen!2sid!4v1719239605794!5m2!1sen!2sid"
              width="100%"
              height="250"
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              title="Asta Karya Jakarta Office"
            ></iframe>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
